import styled from "styled-components";

const LoginB = styled.button`
  font: Segoe UI Regular;
  font-size: 15px;
  background: #2f2f2f;
  color: #ffffff;
  border-color: #8c8c8c;
  border-width: 1px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 41px;
  border-radius: 5px;
  img {
    padding-right: 0.5em;
  }
  cursor: pointer;
`;

export { LoginB };
