import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import OtherLoader from "../../components/OtherLoader/OtherLoader";
import { StyledBody } from "../../styles/styles";
import StyledLookup from "./styles";
import Card from "../../components/AnimatedCard";
import Check from "../../icons/Check/Check";
import { Context } from "../../App";
import DynamicModal from "../../components/DynamicModal/DynamicModal";
import { useHistory } from "react-router-dom";
import { CardTravel } from "@material-ui/icons";
const moment = require("moment");

const Lookup = (props) => {
  let history = useHistory();
  //context
  const {
    setIsLoading,
    isLoading,
    show,
    setProducts,
    setStage,
    setCart,
    cart,
    setShow,
    setError,
    setValid,
    projNum,
    setProjNum,
  } = useContext(Context);
  const lookupProj = (e) => {
    e.preventDefault();
    if (projNum.replace(/\D/g, "") !== "") {
      // alert(projNum.replace(/\D/g, ""));
      setIsLoading(true);
      //hit axios call
      axios
        .post(
          "https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/service_pricer/get_service_products",
          {
            MSPROJ: projNum,
          }
        )
        .then((v) => {
          console.log(v.data.data);
          setProducts(v.data.data);
          let cartPopulate = v.data.data.map((i) => ({
            customer_charge: i.actual_customer_charge,
            already_sub: true,
            ...i,
          }));
          setCart([...cart, ...cartPopulate]);

          setStage(2);
          history.push("/home");
          setIsLoading(false);
          if (v.data.data.length > 0) {
            setShow(true);
            setValid(`Found ${v.data.data.length} already submitted products`);
          }
        })
        .catch((e) => {
          // alert("Error fetching");
          setProjNum("");
          setIsLoading(false);
          setError("Error fetching data");
        });
    } else {
      // alert("make sure to enter project number");
      setError("Make sure to enter a project number!");
    }
  };
  return isLoading ? (
    <OtherLoader />
  ) : (
    <StyledBody show={show}>
      <StyledLookup>
        <div className="lookup">
          <div className="innercard">
            <span className="instruction">Enter Project Number</span>
            <input
              className="oppinput"
              placeholder="project number"
              onChange={(e) => setProjNum(e.currentTarget.value)}
              value={projNum}
              // onKeyDown={(e) => e.keyCode === 13 && onSearch(e)}
            />
            <button onClick={lookupProj} className="buttonStyle">
              SEARCH
            </button>
          </div>
        </div>
      </StyledLookup>
    </StyledBody>
  );
};

export default Lookup;
