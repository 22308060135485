import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
import OtherLoader from "../components/OtherLoader/OtherLoader";
import { StyledBody } from "../styles/styles";
import Card from "../components/AnimatedCard";
import { Context } from "../App";
import axios from "axios";
// import { allWindows } from "../Data/window";
// const getWindowPricing = () => allWindows;
// axios.get(
//   "https://azhlooipqc.execute-api.us-east-2.amazonaws.com/prod/staticwindows"
// );

const Home = ({ history }) => {
  //context
  const {
    show,
    setShow,
    isLoading,
    setIsLoading,
    setProductSpecific,
  } = useContext(Context);

  const clickProduct = (e) => {
    setShow(false);
    setIsLoading(true);

    if (+e.currentTarget.id !== 6) {
      axios
        .post(
          `https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/service_pricer/get_product_details`,
          {
            PROD_ID: e.currentTarget.id,
          }
        )
        .then((v) => {
          console.log(v.data.data[0]);

          setProductSpecific(v.data.data[0]);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);

          alert("Error loading product");
        });
    } else {
      //load other
      // alert("OTHER");
      setProductSpecific({
        customer_charge: 0,
        id: 6,
        labor_cost: 0,
        labor_minutes: 0,
        product_name: "",
        tech_commission: 0,
        unit_cost: 0,
      });
      setIsLoading(false);
    }
  };
  return isLoading ? (
    <OtherLoader />
  ) : (
    <StyledBody show={show}>
      {[
        "Sense Consumption Meters",
        "Vehicle Charging Station",
        "Interlock Kits & Generator Plugs",
        "Manual Transfer Switch",
        "Whole Home Surge Suppressor",
        "Other",
      ].map((v, index) => {
        console.log("here is v");
        console.log(v);
        return <Card onClick={clickProduct} name={v} />;
      })}
      {/* <p style={{ marginTop: "100px" }}>HI</p> */}
    </StyledBody>
  );
};

export default Home;
