import React, { useState, useContext, useEffect } from "react";
import "./App.css";
import Lookup from "./container/Lookup/Lookup";
import PublicRoute from "./AuthRouter";
import Header from "./components/Header";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Home from "./container/home";
import SideNav from "./components/SideNav";
import TextField from "@material-ui/core/TextField";
import MomentumModal from "./components/momentumModal/MomentumModal";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const theme = {
  lightBlue: "#3cc7fa",
  midnightBlue: "#001649",
  lightSteelBlue: "#E3E9EE",
  slateGrey: "#6f7d8B",
  cadetBlue: "#658eb3",
  challengeGreen: "#49B54A",
  alertRed: "#FF0000",
  backgroundDarkGrey: "#f5f5f5",
  backgroundLightGrey: "#e5e5e5",
  font: "Roboto, sans-serif",
};

const Context = React.createContext({});
export { Context };

const AppRouter = () => {
  const [projNum, setProjNum] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [azureAccount, setAzureAccount] = useState("");
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);
  const [stage, setStage] = useState(1);
  const [cart, setCart] = useState([]);
  const [productSpecific, setProductSpecific] = useState({});
  const [otherProducSpeicifc, setOtherProductSpecific] = useState({});
  const [valid, setValid] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    if (valid !== "") {
      NotificationManager.success(``, `${valid}`, 3000);
      setValid("");
    }
    if (error !== "") {
      NotificationManager.error(``, `${error}`, 3000);
      setError("");
    }
  }, [valid, error]);
  const addToCart = (e) => {
    if (
      productSpecific.product_name === "" ||
      +productSpecific.customer_charge === 0
    ) {
      setError("Please enter product name and price");
    } else {
      let tmpCart = cart;
      console.log(productSpecific);
      tmpCart.push({
        ...productSpecific,
        already_sub: false,
        isOther: +productSpecific.id === 6 ? true : false,
      });
      console.log("here is tmpCart");
      console.log(tmpCart);
      setCart(tmpCart);
      setProductSpecific({});
      setShow(true);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Context.Provider
        value={{
          setError,
          setValid,
          isLoading,
          azureAccount,
          setAzureAccount,
          setIsLoading,
          show,
          products,
          setProducts,
          stage,
          setStage,
          cart,
          setCart,
          setShow,
          productSpecific,
          setProductSpecific,
          projNum,
          setProjNum,
        }}
      >
        <div className="App">
          <BrowserRouter>
            <Header />
            <Switch />
            <PublicRoute exact path="/">
              <Lookup />
            </PublicRoute>
            <PublicRoute exact path="/home">
              <Home />
            </PublicRoute>
            <SideNav />
            <MomentumModal showModal={Object.keys(productSpecific).length > 0}>
              {Object.keys(productSpecific).length > 0 && (
                <>
                  <h2>
                    {+productSpecific.id !== 6 ? (
                      `${productSpecific.product_name}`
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          style={{
                            marginTop: "0.5em",
                            width: "30vw",
                            fontSize: "1.1em",
                          }}
                          type="text"
                          placeholder="Enter product name"
                          value={productSpecific.product_name}
                          onChange={(e) =>
                            setProductSpecific({
                              ...productSpecific,
                              product_name: e.currentTarget.value.toUpperCase(),
                            })
                          }
                          // value={productSpecific.product_name}
                        />
                      </div>
                    )}
                  </h2>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                        gridGap: "5px",
                        border: "none",
                        padding: "0.5em",
                        borderRadius: "5px",
                        color: "white",
                        background: "#336699",
                        fontWeight: "bold",
                        marginBottom: "0.2em",
                      }}
                    >
                      {+productSpecific.id !== 6 && (
                        <div class="grid-item">Install Time:</div>
                      )}
                      {+productSpecific.id !== 6 && (
                        <div class="grid-item">{`${(
                          productSpecific.labor_minutes / 60
                        ).toFixed(1)} hours`}</div>
                      )}
                      <div class="grid-item">Total Cost:</div>
                      <div class="grid-item">
                        <input
                          style={{ padding: "0.2em", width: "75px" }}
                          type="number"
                          min="0.01"
                          step="0.01"
                          value={productSpecific.customer_charge}
                          onChange={(e) =>
                            setProductSpecific({
                              ...productSpecific,
                              customer_charge: e.currentTarget.value,
                            })
                          }
                        />
                      </div>

                      {/* {`$${productSpecific.customer_charge.toFixed(
                        2
                      )}`}</div> */}
                    </div>
                    <textarea
                      className="newPost"
                      maxlength="250"
                      placeholder="Additional Notes"
                      rows="4"
                      cols="50"
                      onChange={(e) =>
                        setProductSpecific({
                          ...productSpecific,
                          notes: e.currentTarget.value,
                        })
                      }
                    ></textarea>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "0.5em",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        style={{
                          marginRight: "0.5em",
                          padding: "1em",
                          background: "#FF5A5F",
                          border: "none",
                          borderRadius: "7px",
                          fontWeight: "bold",
                          width: "20%",
                          color: "white",
                          boxShadow: "0 5px 5px rgba(0, 0, 0, 0.15)",
                        }}
                        onClick={(e) => setProductSpecific({})}
                      >
                        CANCEL
                      </button>
                      <button
                        style={{
                          padding: "1em",
                          background: "#25D366",
                          border: "none",
                          borderRadius: "7px",
                          fontWeight: "bold",
                          width: "20%",
                          color: "white",
                          boxShadow: "0 5px 5px rgba(0, 0, 0, 0.15)",
                        }}
                        onClick={addToCart}
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                </>
              )}
            </MomentumModal>
          </BrowserRouter>
        </div>
        <NotificationContainer />
      </Context.Provider>
    </ThemeProvider>
  );
};

export default AppRouter;
