import styled from "styled-components";

// const expand = keyframes`
//   from{
//     transform: scale(0) ;
//   }
//   to{
//     transform: scale(1) ;
//   }
// `;

const DynamicModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${props => props.theme.font};
  .modalMain {
    position: fixed;
    border-radius: 8px;
    background-color: #ffffff;
    width: ${props => props.width};
    padding: 15px 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: left 0.5s;
    left: ${props => (props.sideNavOpen ? "calc(50% + 150px)" : "50%")};
  }
  .tableDiv {
    overflow: auto;
    max-height: 450px;
    padding: 0 30px;
  }
`;

export default DynamicModalStyle;
