import styled from "styled-components";

const StyledLookup = styled.div`
  .lookup {
    height: 90vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
  }

  .innercard {
    padding: 10em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    background: #21283e;
    justify-content: center;
    align-items: center;
  }
  .oppinput {
    font-size: 1.2em;
  }
  .instruction {
    color: white;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  .buttonStyle {
    background: #3082b8;
    color: white;
    margin-top: 1em;
    width: 50%;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: bold;
    border: none;
    outline: none;
    padding: 0.7em;
  }
  .buttonStyleCancel {
    background: #ff4c4c;
    color: white;
    margin-top: 1em;
    width: 50%;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: bold;
    border: none;
    outline: none;
    padding: 0.7em;
  }

  .proptitle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .checkcontain {
    padding-left: 0.5em;
  }

  .addProp {
    padding: 5em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    background: #21283e;
    justify-content: center;
    align-items: center;
  }
  .word {
    width: 100%;
    text-align: center;
  }

  .timestamp {
  }

  .addProp > span {
    color: white;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  .addProp > input {
    font-size: 1.2em;
  }
  .buttonContainer {
    display: "flex";
    width: 100%;
    align-items: "center";
    justify-content: "center";
  }

  .mainproposal {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background: white;
    width: 100vw;
  }
  .proposalcard {
    padding: 1.3em;
    margin: 0.75em;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    background: #21283e;
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .proposalcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100vh;
    overflow: hidden;
  }
  .updated {
    display: flex;
    flex-direction: column;
    background: white;
    color: black;
    align-items: center;
    justify-content: center;
    padding: 1em;
    margin: 0.5em;
    border-radius: 5px;
  }

  .addbutton {
    border: none;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    margin: 1em;
    padding: 0.5em;
    font-size: 1.5em;
    color: white;
    background: #34b93d;
    font-weight: bold;
    border-radius: 10px;
    width: 7%;
    text-align: center;
  }
  .addbutton: hover {
    cursor: pointer;
  }
`;

export default StyledLookup;
