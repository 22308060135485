// const axios = require("axios");

function getPrice(price, ui, type) {
  return type === "single" ? price : ui * price;
}

export const noSpecialChar = (text) => {
  return text.replace(/[^0-9a-z]/gi, " ");
};

export const currency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const firstCharCap = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((s) => {
      if (s !== "oz" && s !== "oz.") {
        return s.charAt(0).toUpperCase() + s.substring(1);
      } else {
        return s;
      }
    })
    .join(" ");
};

export const codeToName = (code) => {
  switch (code.toUpperCase()) {
    case "DH":
      return "double hung";
    case "CS":
      return "casement";
    case "AW":
      return "awning";
    case "PW":
      return "picture window";
    case "PWC":
      return "picture window casement";
    case "SL":
      return "slider (2 lites)";
    case "SL3":
      return "slider (3 lites)";
    case "GARDENBAY":
      return "ventana garden bay";
    case "BAYBOW":
      return "bow windows";
    case "GSLD":
      return "camelot sliding glass door";
    case "BAY":
      return "bay windows";
    default:
      break;
  }
};

export const verifyCanGenerateWindow = ({
  mts_Height,
  mts_Width,
  tmpGridStyle,
  mts_Style,
  mts_color,
}) => {
  if (
    +mts_Height !== 0 &&
    +mts_Width !== 0 &&
    tmpGridStyle !== "" &&
    mts_Style !== "" &&
    mts_color !== "" &&
    +mts_Height !== undefined &&
    +mts_Width !== undefined &&
    tmpGridStyle !== undefined &&
    mts_Style !== undefined &&
    mts_color !== undefined
  ) {
    return "visible";
  } else {
    return "hidden";
  }
};
export const codeToPictureAPI = (code) => {
  switch (code.toLowerCase()) {
    case "dh":
      return "DOUBLEHUNG";
    case "cs":
      return "CASEMENT";
    case "aw":
      return "AWNING";
    case "pw":
    case "pwc":
      return "PICTURE";
    case "sl":
    case "sl3":
      return "SLIDER";
    case "baybow":
      return "BOW WINDOWS";
    case "gsld":
      return "CAMELOT SLIDING GLASS DOOR";
    case "bay":
      return "BAY WINDOWS";
    default:
      break;
  }
};

export const getContourGridType = (selectedOptions) => {
  switch (selectedOptions["mts_color"].toLowerCase()) {
    case "almond":
      return "CA";
    case "white":
      return "CW";
    case "cocoa-white":
      return "CT";
    default:
      return "err";
  }
};
