import React, { useEffect } from "react";
import { MomentumStyle } from "./styles";
import { motion, AnimatePresence } from "framer-motion";
const MomentumModal = ({ children, width, showModal }) => {
  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <MomentumStyle id="modalBackground" width={width}>
            <div className="modalMain">{children}</div>
          </MomentumStyle>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MomentumModal;
