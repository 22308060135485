import React from "react";

export default class Check extends React.Component {
  render() {
    return (
      <svg width="24" height="24">
        <rect width="24" height="24" fill="none" rx="0" ry="0" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.0683 4.57652C18.6778 4.186 18.0446 4.186 17.6541 4.57652L9.17453 13.0561L6.34498 10.2265C5.95445 9.836 5.32129 9.836 4.93076 10.2265L2.80827 12.349C2.41775 12.7395 2.41775 13.3727 2.80827 13.7632L5.63783 16.5928L5.63327 16.5973L8.46287 19.4269C8.85339 19.8175 9.48656 19.8175 9.87708 19.4269L21.1908 8.11323C21.5813 7.7227 21.5813 7.08954 21.1908 6.69901L19.0683 4.57652Z"
          fill="#21d33e"
        />
      </svg>
    );
  }
}
