import React, { useContext, useEffect, useState } from "react";
import { IconButton, AppBar, Popover, Typography } from "@material-ui/core";
import { ShoppingCart, Lock, LockOpen } from "@material-ui/icons";
import { Context } from "../App";
import { StyledHeader, StyledButton } from "../styles/styles";
import PinModal from "../components/PinModal/PinModal";
// import logo from "../assets/MH_Logo.png";
import logo from "../assets/mopower.png";
import DynamicModal from "../components/DynamicModal/DynamicModal";
import { codeToName } from "../components/utils/helper";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { useHistory } from "react-router-dom";
// import {
//   NotificationContainer,
//   NotificationManager
// } from "react-notifications";
// import "react-notifications/lib/notifications.css";

const Header = ({ accountInfo }) => {
  let history = useHistory();
  const {
    isLoading,
    stage,
    setStage,
    setShow,
    show,
    setCart,
    setProjNum,
  } = useContext(Context);

  //see if reroute works?
  //reload page?
  //   useEffect(() => {
  //     //handle token
  //     !localStorage.getItem("msal.idtoken")
  //       ? window.location.reload()
  //       : console.log("logged in");
  //   });

  const [copy, setCopy] = useState(false);

  const projLookup = () => {
    setStage(1);
    setShow(false);
    setCart([]);
    setProjNum("");
    history.push("/");
  };

  return (
    <StyledHeader>
      <AppBar
        className="navbar"
        style={{ alignSelf: "flex-start", display: "flex", maxWidth: "100vw" }}
      >
        <img
          className="logo_header"
          src={logo}
          style={{ touchAction: "manipulation" }}
          alt="momentum_logo"
        />
        {stage !== 1 && (
          <button className="opp" onClick={projLookup}>
            Project Lookup
          </button>
        )}

        {/* {newCart.OPP_NUM !== "" && newCart.PROPOSAL_NAME !== "" && (
          <button className="opp" onClick={newProp}>
            New Proposal
          </button>
        )}
        {newCart.OPP_NUM !== "" && newCart.PROPOSAL_NAME !== "" && (
          <button className="opp" onClick={windowPrice}>
            Window Pricer
          </button>
        )} */}
        {/* <IconButton className="Admin badge" onClick={makeAdmin}>
          {isAdmin ? (
            <Lock style={{ fontSize: "36px", color: "#001649" }} />
          ) : (
            <LockOpen style={{ fontSize: "36px", color: "#001649" }} />
          )}
        </IconButton> */}
        {stage !== 1 && (
          <IconButton
            className="menuButtonShop badge"
            aria-label="Menu"
            onClick={(e) => setShow(!show)}
          >
            <ShoppingCart style={{ fontSize: "36px" }} />
          </IconButton>
        )}
      </AppBar>
    </StyledHeader>
  );
};

export default Header;
