import styled from "styled-components";
import { animated } from "react-spring";

export const StyledImage = styled.div`
  border: solid;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 2em;
`;

export const StyledGrid = styled.div`
  height: 350px;
  border: solid;
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-top: 1em;
  margin-bottom: 2em;
  div.nonprod {
    display: none !important;
  }
  .gridTitle {
    padding-left: 1em;
  }
  h2.gridTitle {
    text-decoration: underline;
  }
  div.gridOptions {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  img {
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
  }
  div.colonial {
    height: 100%;

    width: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  div.anne {
    height: 100%;

    width: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  div.diamond {
    height: 100%;

    width: 190px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  div.none {
    height: 100%;

    width: 190px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  h3 {
    text-decoration: underline;
  }
  .g_option {
    transition: all 0.2s ease-in-out;
  }
  .g_option:hover {
    cursor: pointer;
  }
  .gridColor {
    padding: 0 1em 1em 1em;
  }
  .hidden {
    display: none;
  }
  .clicked {
    border-style: solid;
    border-color: #001649;
  }

  @media (max-width: 1024px) {
    width: 95%;
    div.nonprod {
      display: none;
    }
  }

  @media (max-width: 768px) {
    height: 600px;
    div.nonprod {
      display: none;
    }
    width: 95%;

    padding-bottom: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    div.gridOptions {
      display: grid;
      height: 100%;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }
  }
`;

export const StyledButton = styled.button`
  margin: ${(props) => props.margin || "30px 0 0 30px"};
  align-self: ${(props) => props.alignSelf};
  color: white;
  display: flex;
  width: ${(props) => props.width || "100px"};
  justify-content: space-evenly;
  align-items: center;
  background-color: #001d49;
  padding: 1em 10px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  visibility: ${(props) => props.displayVal};
  transition: transform 0.2s;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledSidebar = styled(animated.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 64px;
  bottom: ${(props) => (!props.open ? null : "0")};
  transform: translateX(${(props) => (!props.open ? "-300px" : "0")});
  width: 300px;
  z-index: 5;

  transition: all 0.5s;
  border-right: 1px solid ${(props) => props.theme.backgroundLightGrey};
  bottom: 0;
  font-family: ${(props) => props.theme.font};
  background-color: white;
  z-index: 1;
  overflow-y: auto;
  color: white;
  .shoppingCart {
    color: ${(props) => props.theme.midnightBlue};
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    margin: 25px 0;
  }
  .emptyShoppingCart {
    padding: 25px 0;
    color: ${(props) => props.theme.midnightBlue};
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    margin: 30px 0;
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
  .hoverIcons {
    &:hover {
      opacity: 0.8;
      transform: scale(1.1);
    }
  }
  .cell {
    text-align: center;
  }
`;

export const FormCard = styled.div`
  width: 90%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.midnightBlue};
  font-family: ${(props) => props.theme.font};
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 0 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);

  .priceHidden {
    display: none;
  }
  h1 {
    padding: 10px;
    margin: 0px;
    display: flex;
    box-shadow: 0 2px 1px -2px gray;
    align-items: center;
  }
  .inputMargin {
    margin: 5px 0;
  }
  .inputMarginForms {
    margin: 20px 0 5px 0;
  }
  .headerIcon {
    margin-right: 5px;
  }
  span {
  }
  form {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .errorField {
    margin-top: 5px;
    text-align: center;
    color: ${(props) => props.theme.alertRed};
    font-size: 14px;
  }
  .successField {
    margin-top: 5px;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
  }
  .formInput {
    padding: 5px 0;
  }
  .buttonTwin {
    justify-content: center;
    display: flex;
    align-self: center;
  }
  .uploadImage {
    margin: 0 auto;
    justify-content: center;
    width: 50%;
  }
  @media (max-width: 1024px) {
    width: ${(props) => (props.show ? "60%" : "90%")};
    transform: ${(props) => (props.show ? "translateX(20%)" : "")};
    transition: width 0.5s, transform 0.5s;
    img {
      transform: ${(props) => (props.show ? "scale(0.9)" : "")};
      transition: transform 0.5s;
    }
  }
  @media (max-width: 768px) {
    width: ${(props) => (props.show ? "50%" : "90%")};
    transform: ${(props) => (props.show ? "translateX(35%)" : "")};
    transition: width 0.5s, transform 0.5s;
    img {
      transform: ${(props) => (props.show ? "scale(0.9)" : "")};
      transition: transform 0.5s;
    }
  }
`;

export const StyledBody = styled.div`
  background-color: #e9e9e9;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 50px;

  .card {
    width: 40%;
    padding: 20px;
    margin: 15px 0;
    background-color: white;
    border-radius: 10px;
    display: flex;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    .card {
      width: ${(props) => (props.show ? "60%" : "95%")};
      transform: ${(props) => (props.show ? "translateX(20%)" : "")};
      transition: width 0.5s, transform 0.5s;
    }
  }
  @media (max-width: 768px) {
    .card {
      width: ${(props) => (props.show ? "50%" : "95%")};
      transform: ${(props) => (props.show ? "translateX(35%)" : "")};
      transition: width 0.5s, transform 0.5s;
    }
  }

  img {
    width: 300px;
  }
`;

export const StyledHeader = styled.div`
  .MuiAppBar-positionFixed {
    right: auto;
  }
  .logo_header {
    transform: scale(0.75);
    height: 70px;
    margin-left: -35px;
  }
  .wrapedit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .buttonwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #21283e;
    padding: 2em;
    border-radius: 5px;
  }
  .buttonStyle {
    background: #21283e;
    color: white;
    margin-top: 1em;
    width: 50%;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: bold;
    border: none;
    outline: none;
    padding: 0.7em;
  }
  .outtercopy {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .copyinput {
    margin: 0.5em;
    font-size: 1.3em;
  }

  .navbar {
    min-height: 64px;
    background-color: #ffffff;
    position: fixed;
    display: flex;
    flex-direction: row;
    box-shadow: none;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.22);
  }
  .opp {
    border: none;
    padding: 1em;
    background: ${(props) => props.theme.midnightBlue};
    color: white;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
    box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
    font-weight: bold;
    font-size: 1em;
    outline: none;
    border-radius: 5px;
  }
  .toolbarLeft {
    width: 300px;
    background-color: ${(props) => props.theme.midnightBlue};
    padding: 0;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
    box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
  }
  .menuButton {
    color: #ffffff;
  }
  .menuButtonShop {
    font-size: 18px;
    margin-right: 15px;
    color: ${(props) => props.theme.midnightBlue};
  }
  .Admin {
    align-self: flex-end;
  }
  .imgHeader {
    margin-left: 27px;
    width: 60%;
  }
  .toolbar {
    padding: 0;
    max-height: 64px;
    width: calc(100% - 300px);
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
    box-shadow: 0px 1px 5px 0px rgba(28, 52, 76, 1);
  }
  .grow {
    flex-grow: 1;
    color: ${(props) => props.theme.midnightBlue};
    font-family: ${(props) => props.theme.font};
    font-size: 28px;
    margin-left: 30px;
  }
  img {
    height: 70px;
    margin-left: 10px;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  p {
    color: black;
    font-size: 1.2rem;
  }
  .badge {
    position: relative;
  }
  .badge[data-badge]:after {
    content: attr(data-badge);
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 0.7em;
    color: white;
    background-color: ${(props) => props.theme.midnightBlue};
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 50%;
    box-shadow: 0 0 1px #333;
  }
`;
