import React from "react";
// import twoslider from "../assets/2slider.jpg";
// import awning from "../assets/awning.jpg";
// import casement from "../assets/casement.jpg";
// import pictureDH from "../assets/PictureDH.jpg";
// import pictureCS from "../assets/PictureCS.jpg";
// import dh from "../assets/DH.jpg";
// import threeSlide from "../assets/3SLIDE.jpg";
// import gardenbay from "../assets/gardenbay.jpeg";
// import baybow from "../assets/bay_bow.jpg";
// import glass_sliding from "../assets/sliding_glass.png";
// import bay from "../assets/baywndw.png";

//v2 photos
import charger from "../assets/charger.png";
import ikit_genplug from "../assets/ikit_genplug.png";
import mts from "../assets/mts.png";
import scm from "../assets/scm.png";

const pics = [
  { name: "Sense Consumption Meters", img: scm, id: 1 },
  { name: "Vehicle Charging Station", img: charger, id: 2 },
  { name: "Interlock Kits & Generator Plugs", img: ikit_genplug, id: 3 },
  { name: "Manual Transfer Switch", img: mts, id: 4 },
  { name: "Whole Home Surge Suppressor", img: mts, id: 5 },
  { name: "Other", img: mts, id: 6 },
];

const Card = ({ onClick, name }) => {
  return (
    <div
      className="card"
      id={pics.filter((x) => x.name === name)[0].id}
      key={pics.filter((x) => x.name === name)[0].id}
      onClick={onClick}
      style={{
        textAlign: "center",
        height: "200px",
        width: "90vw",
        background: "#336699",
      }}
    >
      <h1 style={{ color: "white" }}>{name}</h1>
      {/* <img
        src={pics.filter((x) => x.name === name)[0].img}
        alt={pics.filter((x) => x.name === name)[0].name}
        height="350px"
      /> */}
    </div>
  );
};

export default Card;
