import React, { useContext, useState } from "react";
import { Context } from "../App";
import { StyledButton } from "../styles/styles";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { StyledSidebar } from "../styles/styles";
import axios from "axios";
import { codeToName } from "../components/utils/helper";

const SideNav = ({ history }) => {
  const {
    show,
    cart,
    setCurrentWindow,
    setShow,
    setEditModal,
    setCart,
    setProducts,
    setIsLoading,
    projNum,
    azureAccount,
  } = useContext(Context);

  const pushCart = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("heres final cart");
    console.log(cart);
    let cart_post = cart.filter((v) => v.already_sub === false);
    axios
      .post(
        "https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/service_pricer/post_cart",
        {
          CART_ARR: cart_post,
          PROJ: projNum,
          USER: azureAccount.account.userName,
        }
      )
      .then((v) => {
        console.log(v.data);
        axios
          .post(
            "https://hscabuwbfe.execute-api.us-east-1.amazonaws.com/latest/service_pricer/get_service_products",
            {
              MSPROJ: projNum,
            }
          )
          .then((v) => {
            console.log(v.data.data);
            setProducts(v.data.data);
            let cartPopulate = v.data.data.map((i) => ({
              customer_charge: i.actual_customer_charge,
              already_sub: true,
              ...i,
            }));
            setCart([...cartPopulate]);
            setIsLoading(false);
          });
      });
  };

  const openModal = (e) => {
    e.preventDefault();
    setCurrentWindow({});
    console.log(e.currentTarget.id);
    console.log(cart[e.currentTarget.id]);
    setCurrentWindow(cart[e.currentTarget.id]);
    setEditModal(true);
  };
  const isSubmitted = (item) => {
    if (item.already_sub) {
      return {
        color: "blue",
        fontWeight: "bold",
      };
    } else {
      return {
        color: "black",
      };
    }
  };

  return (
    <>
      <StyledSidebar open={show}>
        {cart && Object.keys(cart).length > 0 ? (
          <>
            <Table className="shoppingCart">
              <TableHead>
                <TableRow>
                  <TableCell className="cell">
                    <b>Product</b>
                  </TableCell>
                  <TableCell className="cell">
                    <b>Price</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart
                  ? Object.keys(cart).map((x, i) => {
                      return (
                        <TableRow
                          style={{ cursor: "pointer" }}
                          key={i}
                          onClick={openModal}
                          id={x}
                        >
                          <TableCell
                            className="cell"
                            style={isSubmitted(cart[x])}
                            id={x}
                          >
                            {cart[x].product_name}
                          </TableCell>

                          <TableCell
                            className="cell"
                            style={isSubmitted(cart[x])}
                            id={x}
                          >
                            {`$${cart[x].customer_charge}`}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
                {/* {cart ? (
                  <TableRow>
                    <TableCell className="cell">
                      <b>Total</b>
                    </TableCell>

                    <TableCell className="cell">
                      ${parseFloat(+getGrandTotal()).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ) : null} */}
              </TableBody>
            </Table>

            <StyledButton
              onClick={(e) => console.log("yes")}
              onClick={pushCart}
              margin="15px 0"
            >
              SUBMIT
            </StyledButton>
          </>
        ) : (
          <div className="emptyShoppingCart">Cart Empty</div>
        )}
      </StyledSidebar>
    </>
  );
};

export default SideNav;
